import {PagingParams, SortingInfo} from '../api';
import {ChangeEvent, KeyboardEvent, useState} from 'react';
import {SortAlphaDown, SortAlphaUpAlt, SortNumericDown, SortNumericUpAlt} from '../components/icons';


export interface GenericSelectTableHeaderParams<T> {
  title: string
  prop: Extract<keyof T, string>
  setFilter: (prop: Extract<keyof T, string>, val: string) => void
  paging: PagingParams
  changeSort: (prop: string) => void
  sortInfo: SortingInfo
  options: string[]
  colSpan?: number
  rowSpan?: number
}


export function GenericSelectTableHeader<T>(
  {
    title,
    prop,
    setFilter,
    paging,
    changeSort,
    sortInfo,
    options,
    colSpan,
    rowSpan
  } : GenericSelectTableHeaderParams<T>
): JSX.Element {

  const [selected, setSelected] = useState('')


  function onChange(e: ChangeEvent<HTMLSelectElement>) {
    setSelected(e.target.value)
    setFilter(prop, e.target.value)
  }

  const sortElement: JSX.Element = paging.sort === prop
    ? (
      sortInfo[prop].dataType === 'alpha'
        ? paging.order === 'asc' ? <SortAlphaDown /> : <SortAlphaUpAlt />
        : paging.order === 'asc' ? <SortNumericDown /> : <SortNumericUpAlt />
    ) : <></>

  return (
    <th scope="col" onClick={() => changeSort(prop)}
      colSpan={colSpan}
      rowSpan={rowSpan}
    >
      {title}
      {sortElement}
      <br/>
      <select
        className="form-select form-select-sm"
        value={selected}
        onChange={onChange}
        onClick={(e) => { e.stopPropagation() }}>
        <option></option>
        {options.map((o, i) => (<option value={o} key={i}>{o}</option>))}
      </select>
    </th>
  )
}
