import {useParams, useNavigate, Link} from 'react-router-dom'
import {
  CountriesList,
  DistrictsList,
  RegionsList,
  SubregionsList
} from './districts'

export function DistrictManager(): JSX.Element {

  const {section, id} = useParams();

  return (
    <div className="DistrictManager">
      {(!section || section === 'districts') &&
        <DistrictsList />
      }
      {section === 'countries' &&
        <CountriesList />
      }
      {section === 'regions' &&
        <RegionsList />
      }
      {section === 'subregions' &&
        <SubregionsList />
      }
    </div>
  )
}