import {useEffect, useMemo, useState} from 'react';
import {Link, useSearchParams, useParams, useNavigate} from 'react-router-dom';
import {Formik, Form, FormikValues} from 'formik';

import {
  DangerAlert,
  GenericSearchTableHeader,
  Pager,
  LoadablePane
} from '../../components';
import {
  FormButtons,
  FormError,
  LabeledInput, 
  LabeledTextArea,
  LabeledSwitch,
  LabeledSelect,
  SafeForm
} from '../../components/form'
import {
  DistrictSortInfo,
  DbResponseResultSet,
  DbLiteralTypes,
  DistrictDao,
  QueryDistrictFilters,
  PagingParams
} from '../../api';
import {inTryCatch} from '../../lib';
import {useAppContext} from '../../context';

import { DistrictsNav } from './DistrictsNav'

type CountryOption = {
  id: number 
  name: string
}
type RegionOption = {
  id: number 
  name: string
  country_id: number
}

type SubregionOption = {
  id: number 
  name: string
  region_id: number
}

export function DistrictsList(): JSX.Element {

  const [searchParams, setSearchParams] = useSearchParams()
  const {session, logout} = useAppContext()
  const {id} = useParams()
  const navigate = useNavigate();

  const [countries, setCountries] = useState<DbResponseResultSet | undefined>()
  const [regions, setRegions] = useState<DbResponseResultSet | undefined>()
  const [subregions, setSubregions] = useState<DbResponseResultSet | undefined>()
  const [districts, setDistricts] = useState<DbResponseResultSet | undefined>()
  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [error, showError] = useState<string>()

  const [district, setDistrict] = useState<FormikValues>()
  const [loadingDistrict, setLoadingDistrict] = useState(false)
  const [forceReload, setForceReload] = useState(0)
  const [districtError, setDistrictError] = useState<string>()

  const [countryOptions, setCountryOptions] = useState<CountryOption[]>([])
  const [regionOptions, setRegionOptions] = useState<RegionOption[]>([])
  const [subregionOptions, setSubregionOptions] = useState<SubregionOption[]>([])

  const {paging, filter} = useMemo(() => {
    const paging: PagingParams = {
      limit: 100,
      offset: 0,
      sort: 'name',
      order: 'asc',
    }
    if (searchParams.get('limit')) paging.limit = parseInt(searchParams.get('limit') ?? '', 10)
    if (searchParams.get('offset')) paging.offset = parseInt(searchParams.get('offset') ?? '', 10)
    if (searchParams.get('sort')) paging.sort = searchParams.get('sort') ?? 'name'
    if (searchParams.get('order')) paging.order = searchParams.get('order') === 'asc' ? 'asc' : 'desc'

    const filter: QueryDistrictFilters = {
      name: '',
      country_name: '',
      region_name: '',
      subregion_name: '',
      admin_id: '',
      alt_names: ''
    }

    Object.keys(filter).forEach(prop => {
      if (searchParams.get(prop)) {
        /* @ts-ignore */
        filter[prop] = searchParams.get(prop) ?? ''
      }
    })
    return {paging, filter}
  }, [searchParams])

  function setOffset(offset: number) {
    setSearchParams(prev => {
      prev.set("offset", offset.toString())
      return prev
    }, {replace: true})
  }

  function changeFilter(prop: keyof QueryDistrictFilters, val: string) {
    setSearchParams(prev => {
      prev.set(prop, val)
      prev.set('offset', '0')
      return prev
    }, {replace: true})
  }

  function changeSort(prop: string) {
    const newDirection = paging.sort === prop
      ? (
        paging.order === 'asc' ? 'desc' : 'asc'
      ) : 'asc'

    setSearchParams(prev => {
      prev.set('order', newDirection)
      prev.set('sort', prop)
      prev.set('offset', '0')
      return prev
    }, {replace: true})
  }

  async function deleteDistrict(id: number) {
    try {
      setDistrictError(undefined)
      const result = await new DistrictDao(session).deleteDistrict(id)
      navigate(`/districts/districts${window.location.search}`)
    /* @ts-ignore */
    } catch (error: Error) {
      setDistrictError((error as Error).message);
    }
  }

  async function saveDistrict(id: number, values: FormikValues) {
    setDistrictError(undefined)
    try {
      const resultId = await new DistrictDao(session).saveDistrict(id, values)
      navigate(`/districts/districts/${resultId}${window.location.search}`)
    /* @ts-ignore */
    } catch (error: Error) {
      setDistrictError((error as Error).message);
    }
  }

  useEffect(inTryCatch(setIsLoading, showError, async(state) => {
    const result = await new DistrictDao(session).queryDistricts(filter, paging)
    if (!state.mounted) return
    setDistricts(result.rows)
    setCount(result.count)
  }), [paging, filter, session])

  useEffect(inTryCatch(setIsLoading, showError, async(state) => {
    if (id) {
      const dao = new DistrictDao(session)
      const [
        result,
        resultCountries,
        resultRegions,
        resultSubregions
      ] = await Promise.all([
        await dao.getDistrict(parseInt(id)),
        await dao.queryCountries(
          {}, {order: 'asc', sort: 'name', offset: 0, limit: 10000}
        ),
        await dao.queryRegions(
          {}, {order: 'asc', sort: 'name', offset: 0, limit: 10000}
        ),
        await dao.querySubregions(
          {}, {order: 'asc', sort: 'name', offset: 0, limit: 10000}
        )
      ]);
      setDistrict(result || {});
      setCountryOptions(resultCountries.rows as CountryOption[]);
      setRegionOptions(resultRegions.rows as RegionOption[]);
      setSubregionOptions(resultSubregions.rows as SubregionOption[]);
    } else {
      setDistrict(undefined)
    }
  }), [id, forceReload])

  return (
    <div className="CountriesList">
      <div className="row mb-2">
        <div className="col">
          <DistrictsNav />
        </div>
        <div className="col-md-auto text-md-end">
          <Pager count={count} pager={paging} setOffset={setOffset} />
        </div>
      </div>
      <div className="row">
        <div className="col-9">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <GenericSearchTableHeader 
                  title="Country" 
                  prop='country_name'
                  filter={filter}
                  setFilter={changeFilter} 
                  paging={paging}
                  changeSort={changeSort}
                  sortInfo={DistrictSortInfo} 
                />
                <GenericSearchTableHeader 
                  title="Region" 
                  prop='region_name'
                  filter={filter}
                  setFilter={changeFilter} 
                  paging={paging}
                  changeSort={changeSort}
                  sortInfo={DistrictSortInfo} 
                />
                <GenericSearchTableHeader 
                  title="Subregion" 
                  prop='subregion_name'
                  filter={filter}
                  setFilter={changeFilter} 
                  paging={paging}
                  changeSort={changeSort}
                  sortInfo={DistrictSortInfo} 
                />
                <GenericSearchTableHeader 
                  title="District Name" 
                  prop='name'
                  filter={filter}
                  setFilter={changeFilter} 
                  paging={paging}
                  changeSort={changeSort}
                  sortInfo={DistrictSortInfo} 
                />
                <GenericSearchTableHeader 
                  title="Admin ID" 
                  prop='admin_id'
                  filter={filter}
                  setFilter={changeFilter} 
                  paging={paging}
                  changeSort={changeSort}
                  sortInfo={DistrictSortInfo} 
                />
                <GenericSearchTableHeader 
                  title="Alt Names" 
                  prop='alt_names'
                  filter={filter}
                  setFilter={changeFilter} 
                  paging={paging}
                  changeSort={changeSort}
                  sortInfo={DistrictSortInfo} 
                />
                <GenericSearchTableHeader 
                  title="Geoconnect ID" 
                  prop='geoconnect_id'
                  filter={filter}
                  setFilter={changeFilter} 
                  paging={paging}
                  changeSort={changeSort}
                  sortInfo={DistrictSortInfo} 
                />
                <th scope="col">
                  <Link to={`/districts/districts/new${window.location.search}`}
                    className="btn btn-primary btn-tiny"
                  >
                    New
                  </Link>
                </th>
              </tr>
            </thead>
            <tbody>
              {districts?.map(row => (
                <tr key={row.id?.toString()}>
                  <td>
                    <Link to={`/districts/districts?country_name=${row.country_name}`}>
                      {row.country_name}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/districts/districts?region_name=${row.region_name}`}>
                      {row.region_name}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/districts/districts?subregion_name=${row.subregion_name}`}>
                      {row.subregion_name}
                    </Link>
                  </td>
                  <td>{row.name}</td>
                  <td>{row.admin_id}</td>
                  <td>{row.alt_names && Array.isArray(row.alt_names) ? row.alt_names.join(', ') : '' }</td>
                  <td>{row.geoconnect_id}</td>
                  <td>
                    <Link to={`/districts/districts/${row.id}${window.location.search}`}
                      className="btn btn-outline-primary btn-tiny"
                    >
                      Edit
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="col-3">
          <div className="card mb-2">
            <div className="card-header">
              District Details
            </div>
            { !id &&
              <div className="card-body">
                No district selected
              </div>
            }
            { id &&
              <LoadablePane className="card-body" key={id}
                loading={loadingDistrict || !district}
              >
                <Formik
                  enableReinitialize={true}
                  initialValues={district!}
                  onSubmit={async (values, {setSubmitting}) => {
                    setSubmitting(true);
                    await saveDistrict(parseInt(id), values)
                    setSubmitting(false);
                  }}
                >{({isSubmitting, values, dirty}) => (
                  <SafeForm dirty={dirty} className="row">
                    <LabeledInput className="col-12" name="name" label="District Name" />
                    <LabeledSelect className="col-12" name="country_id">
                      <option value="" key={id}></option>
                      {countryOptions.map( ({id, name}) => (
                        <option value={id} key={id}>{name}</option>
                      ))}
                    </LabeledSelect>
                    <LabeledSelect className="col-12" name="region_id">
                      <option value="" key={id}></option>
                      {regionOptions
                        .filter(({country_id}) => `${values.country_id}` === `${country_id}`)
                        .map( ({id, name}) => (
                        <option value={id} key={id}>{name}</option>
                      ))}
                    </LabeledSelect>
                    <LabeledSelect className="col-12" name="subregion_id">
                      <option value=""></option>
                      {subregionOptions
                        .filter(({region_id}) => `${values.region_id}` === `${region_id}`)
                        .map( ({id, name}) => (
                        <option value={id} key={id}>{name}</option>
                      ))}
                    </LabeledSelect>
                    <LabeledTextArea className="col-12" name="alt_names" label="Alt Names" />
                    <LabeledInput className="col-12" name="geoconnect_id" label="Geoconnect ID" type="number" />
                    <FormButtons className="col-12 mt-1"
                      buttons={[
                        {
                          label: id === 'new' ? 'Create' : 'Save',
                          disabled: isSubmitting,
                          submitting: isSubmitting
                        }, {
                          label: 'Cancel',
                          type: 'button',
                          className: 'btn btn-default',
                          onClick: () => navigate(`/districts/districts${window.location.search}`),
                          disabled: isSubmitting
                        }, {
                          label: 'Delete',
                          type: 'button',
                          className: 'btn btn-outline-danger float-end',
                          onClick: () => {
                            if (window.confirm("Are you sure you wish to delete this district?  All associated data must be removed.")) {
                              deleteDistrict(parseInt(id))
                            }
                          },
                          disabled: isSubmitting || (!id)
                        }
                      ]}
                    />
                  </SafeForm>
                )}
                </Formik>
              </LoadablePane>
            }
            { districtError &&
              <div className="card-body">
                <div className="alert alert-danger" role="alert">
                  {districtError}
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}