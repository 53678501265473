import {Api} from './Api';
import {FormikValues} from 'formik';
import {FileData} from '../components/form';
import {SessionData} from './Session';

export class RequestDao extends Api {
  constructor(sessionData: SessionData) { super(sessionData) }

  public async get(id: number | undefined): Promise<FormikValues | undefined> {
    if (typeof id === 'undefined')
      return undefined

    const [
      rows,
      contactRows,
      notTargetedRows,
      fundingRows,
      contributorRows,
      districtRows,
      fileRows,
    ] = await Promise.all([
      this.execute(`
        select 
          r.*,
          f.file_name,
          f.mime_type,
          f.id AS file_id,
          f.upload_date AS file_upload_date
        from public.requests r
        left join public.files f ON r.file_id = f.id
        where r.id = :id
      `, {id}),
      this.execute(`SELECT * FROM public.request_contacts b WHERE b.request_id = :id`, {id}),
      this.execute(`SELECT * FROM public.request_not_targeted b WHERE b.request_id = :id`, {id}),
      this.execute(`SELECT * FROM public.request_funding b WHERE b.request_id = :id`, {id}),
      this.execute(`SELECT * FROM public.request_contributors b WHERE b.request_id = :id`, {id}),
      this.execute(`SELECT * FROM public.request_district_data b WHERE b.request_id = :id`, {id}),
      this.execute(`
        select files.*,
          application_files.id as application_file_id
        from files 
          join application_files on files.id = application_files.file_id
          join requests on requests.application_id = application_files.application_id
        where requests.id = :id`, {id}
      )
    ])
    return {
      ...this.nullsToEmptyStrings(rows[0]),
      contacts: contactRows.map(b => this.nullsToEmptyStrings(b)!),
      notTargeted: notTargetedRows.map(b => this.nullsToEmptyStrings(b)!),
      funding: fundingRows.map(b => this.nullsToEmptyStrings(b)!),
      contributors: contributorRows.map(b => this.nullsToEmptyStrings(b)!),
      districts: districtRows.map(b => this.nullsToEmptyStrings(b)!),
      files: fileRows.map(b => this.nullsToEmptyStrings(b)!)
    }
  }

  public async save(
    values: FormikValues,
    application_id: number,
    file: FileData | undefined,
    files: FileData[] | undefined = undefined
  ): Promise<number> {
    const jsonParam = {
      request : {
        ...this.emptyStringsToNulls(values),
        application_id,
      },
      file: await this.toDbFile(file),
      files: files ? await Promise.all(files.map(file => this.toDbFile(file))) : null
    }
    console.log(jsonParam)
    const r = await this.execute('SELECT app.saveRequest(:json) AS id', {json: JSON.stringify(jsonParam)})

    return r[0].id as number
  }


}
