import {SortAlphaDown, SortAlphaUpAlt, SortNumericDown, SortNumericUpAlt} from '../components/icons';
import {PagingParams, SortingInfo} from '../api';

export function SortTableHeader(
  {
    title,
    prop,
    paging,
    changeSort,
    sortInfo,
    colSpan,
    rowSpan
  } : {
    title: string
    prop: string
    paging: PagingParams
    changeSort: (prop: string) => void
    sortInfo: SortingInfo
    colSpan?: number
    rowSpan?: number
  }
): JSX.Element {


  const sortElement: JSX.Element = paging.sort === prop
    ? (
      sortInfo[prop].dataType === 'alpha'
        ? paging.order === 'asc' ? <SortAlphaDown /> : <SortAlphaUpAlt />
        : paging.order === 'asc' ? <SortNumericDown /> : <SortNumericUpAlt />
    ) : <></>

  return (
    <th scope="col" onClick={() => changeSort(prop)}
      colSpan={colSpan}
      rowSpan={rowSpan}
    >
      {title}
      {sortElement}
    </th>
  )
}
