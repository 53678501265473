import {FieldArrayPanel, LoadablePane} from '../../components';
import {Form, Formik, FormikValues} from 'formik';
import {
  FormButtons,
  FileInput,
  FileData,
  LabeledInput,
  LabeledSelect,
  LabeledSwitch,
  FormError,
  LabeledTextArea,
  SafeForm
} from '../../components/form';
import React, {useEffect, useState} from 'react';
import {useAppContext} from '../../context';
import {inTryCatch} from '../../lib';
import {RequestDao} from '../../api';
import {RequestJrsmFilePanel} from './RequestJrsmFilePanel';

const Months = [
  {id: '', name: ''},
  ...[ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ]
    .map((m, i) => ({
      id: (i + 1).toString(),
      name: m
    }))
]

const MedicineOptions = ['ivermectin']


function RequestContact(
  {
    index,
    remove,
  } : {
    index: number
    remove: (i: number) => void
  }
): JSX.Element {
  return (
    <div className="card text-bg-light mb-3">
      <div className="card-body">
        <LabeledInput name={`contacts.${index}.type`} side  
          labelClass="col-md-3 text-md-end" fieldClass="col-md-9"
        />
        <LabeledInput name={`contacts.${index}.name`} side 
          labelClass="col-md-3 text-md-end" fieldClass="col-md-9"
        />
        <LabeledInput name={`contacts.${index}.department`} side 
          labelClass="col-md-3 text-md-end" fieldClass="col-md-9"
        />
        <LabeledInput name={`contacts.${index}.organization`} side 
          labelClass="col-md-3 text-md-end" fieldClass="col-md-9"
        />
        <LabeledInput name={`contacts.${index}.phone`} side 
          labelClass="col-md-3 text-md-end" fieldClass="col-md-9"
        />
        <LabeledInput name={`contacts.${index}.fax`} side  
          labelClass="col-md-3 text-md-end" fieldClass="col-md-9"
        />
        <LabeledInput name={`contacts.${index}.email`} side  
          labelClass="col-md-3 text-md-end" fieldClass="col-md-9"
        />
        <LabeledTextArea name={`contacts.${index}.mailing_address`} className="col-md-12" />
        <div className="d-flex justify-content-end">
          <button className="btn btn-sm btn-danger" type='button' onClick={() => remove(index)}>Remove contact</button>
        </div>
      </div>
    </div>
  )
}

function RequestNotTargeted(
  {
    index,
    remove,
  } : {
    index: number
    remove: (i: number) => void
  }
): JSX.Element {

  const ageGroupOptions = ['presac', 'sac', 'adult']
  const statusOptions = ['required', 'requested', 'received']

  return (
    <div className="card text-bg-light mb-3">
      <div className="card-body">
        <LabeledSelect name={`notTargeted.${index}.pc_medicine`} side 
          labelClass="col-md-3 text-md-end" fieldClass="col-md-9"
        >
          <option></option>
          {MedicineOptions.map((o, i) => (<option value={o} key={i}>{o}</option> ))}
        </LabeledSelect>
        <LabeledSelect name={`notTargeted.${index}.age_group`} side  
          labelClass="col-md-3 text-md-end" fieldClass="col-md-9"
        >
          <option></option>
          {ageGroupOptions.map((o, i) => (<option value={o} key={i}>{o}</option> ))}
        </LabeledSelect>
        <LabeledInput name={`notTargeted.${index}.num_people`} side  
          labelClass="col-md-3 text-md-end" fieldClass="col-md-9"
        integer />
        <LabeledInput name={`notTargeted.${index}.num_tablets`} side  
          labelClass="col-md-3 text-md-end" fieldClass="col-md-9"
        integer />
        <LabeledInput name={`notTargeted.${index}.source`} side   
          labelClass="col-md-3 text-md-end" fieldClass="col-md-9"
        />
        <LabeledSelect name={`notTargeted.${index}.status`} side   
          labelClass="col-md-3 text-md-end" fieldClass="col-md-9"
        >
          <option></option>
          {statusOptions.map((o, i) => (<option value={o} key={i}>{o}</option> ))}
        </LabeledSelect>
        <div className="d-flex justify-content-end">
          <button className="btn btn-sm btn-danger" type='button' onClick={() => remove(index)}>Remove not targeted</button>
        </div>
      </div>
    </div>
  )
}

function RequestFunding(
  {
    index,
    remove,
  } : {
    index: number
    remove: (i: number) => void
  }
): JSX.Element {
  return (
    <div className="card text-bg-light mb-3">
      <div className="card-body">
        <LabeledInput name={`funding.${index}.disease`} side 
          labelClass="col-md-3 text-md-end" fieldClass="col-md-9"
        />
        <LabeledInput name={`funding.${index}.num_treatments`} side 
          labelClass="col-md-3 text-md-end" fieldClass="col-md-9"
        />
        <LabeledInput name={`funding.${index}.funding_source`} side 
          labelClass="col-md-3 text-md-end" fieldClass="col-md-9"
        />
        <LabeledInput name={`funding.${index}.amount`} side  
          labelClass="col-md-3 text-md-end" fieldClass="col-md-9" integer 
        />
        <LabeledSwitch name={`funding.${index}.funding_secured`} side className="offset-md-3"/>
        <div className="d-flex justify-content-end">
          <button className="btn btn-sm btn-danger" type='button' onClick={() => remove(index)}>Remove funding</button>
        </div>
      </div>
    </div>
  )
}

function RequestContributor(
  {
    index,
    remove,
  } : {
    index: number
    remove: (i: number) => void
  }
): JSX.Element {
  return (
    <div className="card text-bg-light mb-3">
      <div className="card-body">
        <LabeledInput name={`contributors.${index}.title`} side 
          labelClass="col-md-3 text-md-end" fieldClass="col-md-9"
        />
        <LabeledInput name={`contributors.${index}.name`} side 
          labelClass="col-md-3 text-md-end" fieldClass="col-md-9"
        />
        <LabeledInput name={`contributors.${index}.phone`} side 
          labelClass="col-md-3 text-md-end" fieldClass="col-md-9"
          />
        <LabeledInput name={`contributors.${index}.email`} side 
          labelClass="col-md-3 text-md-end" fieldClass="col-md-9" 
        />
        <LabeledInput name={`contributors.${index}.contribution_date`} side
          labelClass="col-md-3 text-md-end" fieldClass="col-md-9" 
        type="date" />
        <div className="d-flex justify-content-end">
          <button className="btn btn-sm btn-danger" type='button' onClick={() => remove(index)}>Remove contributor</button>
        </div>
      </div>
    </div>
  )
}


function ApplicationFile({
  fileData,
  index,
  removable,
  set
}:{
  fileData: FileData
  index: number
  removable: boolean
  set: (fileData: FileData) => void
}): JSX.Element {
  return (
    <div className="card text-bg-light mb-3">
      <div className="card-body">
        <div className="row">
          <FileInput onChange={set} 
            value={fileData}
            uploadText="Upload File"
            removable={removable}
            remove={(remove:boolean) => {
              set({
                ...fileData,
                remove
              })
            }}
          />
        </div>
      </div>
    </div>
  )
}


export function Request(
  {
    id,
    forceAppReload,
    applicationId,
  } : {
    id: number | undefined
    forceAppReload: () => void
    applicationId: number
  }
): JSX.Element {

  const { session } = useAppContext()

  const [request, setRequest] = useState<FormikValues>()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>()
  const [jrsmFile, setJrsmFile] = useState<FileData>()
  const [files, setFiles] = useState<FileData[]>([])
  const [forceReload, setForceReload] = useState(0)

  useEffect(inTryCatch(setLoading, setError, async (state) => {
    if (id) {
      const req = await new RequestDao(session).get(id)
      // console.log(req)
      if (!state.mounted) return
      if (req) {
        setRequest(req)
        setJrsmFile({
          name: req.file_name as string,
          file_id: req.file_id as number,
          type: req.mime_type as string,
          upload_date: req.file_upload_date as string,
        })
        setFiles(req.files.map((file:any) => { return {
          name: file.file_name as string,
          file_id: file.id as number,
          type: file.mime_type as string,
          upload_date: file.upload_date as string,
          application_file_id: file.application_file_id as number,
          remove: file.remove as boolean
        }}))
        return
      }

      setRequest({})
      setJrsmFile(undefined)
    }

    setRequest({contacts: [], notTargeted: [], funding: [], contributors: [], files: []})
    setJrsmFile(undefined)
    setFiles([])

  }), [session, id, forceReload])


  function onSavedJrsmFile(id: number) {
    const isInsert = typeof id === 'undefined'
    if (!isInsert)
      setForceReload(p => p + 1)
    forceAppReload()
  }

  const newContact = () => ({
    type: '',
    name: '',
    department: '',
    organization: '',
    phone: '',
    fax: '',
    email: '',
    mailing_address: '',
  })
  const newNotTargeted = () => ({
    pc_medicine: '',
    age_group: '',
    num_people: '',
    num_tablets: '',
    source: '',
    status: '',
  })
  const newFunding = () => ({
    pc_medicine: '',
    num_treatments: '',
    funding_secured: '',
    funding_source: '',
    amount: '',
  })
  const newContributor = () => ({
    title: '',
    name: '',
    phone: '',
    email: '',
    contribution_date: '',
  })
  const newApplicationFile = () => ({
    description: '',
    file: ''
  })

  return (
    <div className="card-body">
      <LoadablePane loading={loading || !request}>
        <Formik
            initialValues={request!}
            onSubmit={async (values, {setSubmitting}) => {
              console.log(values)
              try {
                setError(undefined)
                const isInsert = typeof id === 'undefined'
                const newId = await new RequestDao(session).save(
                  values,
                  applicationId,
                  jrsmFile,
                  files
                )
                if (!isInsert)
                  setForceReload(p => p + 1)
                forceAppReload()
                setSubmitting(false)
              } catch (e: any) {
                setError(e.message)
              }
            }}
        >
          {({isSubmitting, values, dirty}) => (
            <>
              <RequestJrsmFilePanel jrsmFile={jrsmFile} onSaved={onSavedJrsmFile} requestId={id} applicationId={applicationId} />
              <SafeForm dirty={dirty} className="row mt-4">
                <LabeledInput className="col-3" name="request_year"/>
                <LabeledInput className="col-3" name="mda1_date" type="monthyear" label="PC Round 1"/>
                <LabeledInput className="col-3" name="mda2_date" type="monthyear" label="PC Round 2"/>
                <LabeledSelect className="col-3" name="ivm_arrival_month" label="Arrival Month">
                  {Months.map(({id, name}) => (
                    <option value={id} key={id}>{name}</option>
                  ))}
                </LabeledSelect>
                <hr/>
                <LabeledInput className="col-3" name="ivm_required" integer />
                <LabeledInput className="col-3" name="ivm_in_stock" integer />
                <LabeledInput className="col-3" name="ivm_in_pipeline" integer />
                <LabeledInput className="col-3" name="ivm_requested" integer />
                <hr/>
                <LabeledSwitch className="col-3" name="import_permit_required"
                  labelTrue={'Required'}
                  value={values['import_permit_required']}
                />
                <LabeledInput className="col-4" name="import_permit_time"/>
                <LabeledInput className="col-5" name="import_permit_docs"/>
                <hr/>
                <LabeledSwitch className="col-5" name="consignee_customs_clearance_required"
                  labelTrue={'Required'}
                  value={values['consignee_customs_clearance_required']}
                />
                <LabeledInput className="col-7" name="additional_delivery_info"/>
                <LabeledInput className="col-12" name="additional_info"/>
                <hr />
                <FieldArrayPanel
                  className="col-12"
                  name="files"
                  title="Files"
                  addTitle="Add File"
                  newItem={newApplicationFile}
                  element={(i, remove) => (
                    <ApplicationFile key={i} index={i}
                      fileData={files[i]}
                      removable={true} 
                      set={(fileData) => {
                        if (files[i]) {
                          setFiles(files.map((file, index) => (
                            index === i ? fileData : file 
                          )))
                        } else {
                          setFiles([...files, fileData])
                        }
                      }}
                    />
                  )}
                />
                <hr />
                <FieldArrayPanel
                  className="col-12"
                  name="contacts"
                  title="Contacts"
                  addTitle="Add contact"
                  newItem={newContact}
                  element={(i, remove) => <RequestContact key={i} index={i} remove={remove} />}
                />
                <hr />
                <FieldArrayPanel
                  className="col-12"
                  name="notTargeted"
                  title="Not Targeted"
                  addTitle="Add not targeted"
                  newItem={newNotTargeted}
                  element={(i, remove) => <RequestNotTargeted key={i} index={i} remove={remove} />}
                />
                <hr />
                <FieldArrayPanel
                  className="col-12"
                  name="funding"
                  title="Funding"
                  addTitle="Add funding"
                  newItem={newFunding}
                  element={(i, remove) => <RequestFunding key={i} index={i} remove={remove} />}
                />
                <hr />
                <FieldArrayPanel
                  className="col-12"
                  name="contributors"
                  title="Informations on person(s) who filled in the form"
                  addTitle="Add contributor"
                  newItem={newContributor}
                  element={(i, remove) => <RequestContributor key={i} index={i} remove={remove} />}
                />
                <hr />

                <FormButtons className="col-12 mt-2"
                  buttons={[
                    {
                      label: 'Submit',
                      disabled: isSubmitting,
                      submitting: isSubmitting
                    }, {
                      label: 'Cancel',
                      type: 'button',
                      className: 'btn btn-default',
                      onClick: () => setForceReload(p => p + 1),
                      disabled: isSubmitting
                    }
                  ]}
                />
              </SafeForm>
            </>
          )}
        </Formik>
      </LoadablePane>
      <FormError error={error} />
    </div>
  )
}
