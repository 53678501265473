import {JRSMParserConfig} from './types';
import jrsm2018 from './jrsm2018';
import {parseEndemicityLoaLoa} from './lib';

export default {
  ...jrsm2018,
  request_district_data: {
    ...jrsm2018.request_district_data,
    fields: jrsm2018.request_district_data.fields.map(f => {
      switch (f.name) {
        case 'rounds_lf': return {...f, column: 'Q'}
        case 'rounds_oncho': return {...f, column: 'R'}
      }
      return f
    }).concat([{
      name: 'endemicity_loaloa',
      sheet: /COUNTRY_INFO/,
      column: 'L',
      db: true,
      parser: parseEndemicityLoaLoa
    }])
  }
} as JRSMParserConfig
