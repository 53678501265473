import {PagingParams} from '../api';

export function Pager(
  {
    count,
    pager,
    setOffset,
  } :{
    count: number
    pager: PagingParams
    setOffset: (o: number) => void
  }): JSX.Element {

  let pages = [...Array(Math.ceil(count / pager.limit)).keys()]
  if (pages.length === 0)
    pages.push(0)

  return (

    <div className="row">
      <div className="d-flex justify-content-center">
        { pages.length < 7 &&
          <>
            <div className="align-self-center">{count} Results</div>
            <div className="align-self-center ps-4 pe-2">Page</div>
            <div className="align-self-center">
              {pages.map(p => 
                <button key={p} 
                  onClick={() => setOffset(p * pager.limit)} 
                  type="button" className="btn btn-link px-2" 
                  disabled={p * pager.limit === pager.offset}
                >
                  {p + 1}
                </button>
              )}
            </div>
          </>
        }
        { pages.length >= 7 &&
          <div className="input-group mb-3">
            <label className="input-group-text">
              {count} Results
            </label>
            <button className="btn btn-outline-secondary"
              disabled={pager.offset == 0}
              onClick={() => setOffset(pager.offset - pager.limit)}
            >
              Previous Page
            </button>
            <select className="form-select"
              value={pager.offset}
              onChange={(e) => {
                setOffset(parseInt(e.target.value))
              }}
            >
              {pages.map(p => 
                <option value={p * pager.limit} key={p * pager.limit}>
                  {p + 1}
                </option>
              )}
            </select>
            <button className="btn btn-outline-secondary"
              disabled={pager.offset + pager.limit > count}
              onClick={() => setOffset(
                pager.offset + pager.limit
              )}
            >
              Next Page
            </button>
          </div>
        }
      </div>
    </div>
  )
}
