import { useParams, Link } from 'react-router-dom'

export function DistrictsNav(): JSX.Element {

  const {section, id} = useParams();

  return (
    <ul className="nav nav-tabs">
      <li className="nav-item">
        <Link  to="/districts"
          className={`nav-link ${!section || section === 'districts' ? 'active' : ''}`}
        >
          Districts
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/districts/subregions"
          className={`nav-link ${section === 'subregions' ? 'active' : ''}`}
        >
          Subregions
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/districts/regions"
          className={`nav-link ${section === 'regions' ? 'active' : ''}`}
        >
          Regions
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/districts/countries"
          className={`nav-link ${section === 'countries' ? 'active' : ''}`}
        >
          Countries
        </Link>
      </li>
    </ul>
  )

}