import {useEffect, useMemo, useState} from 'react';
import {Link, useSearchParams, useParams, useNavigate} from 'react-router-dom';
import {Formik, Form, FormikValues} from 'formik';

import {
  DangerAlert,
  GenericSearchTableHeader,
  Pager,
  LoadablePane
} from '../../components';
import {
  FormButtons,
  FormError,
  LabeledInput, 
  LabeledTextArea,
  LabeledSwitch,
  SafeForm
} from '../../components/form'
import {
  CountrySortInfo,
  DbResponseResultSet,
  DbLiteralTypes,
  DistrictDao,
  QueryCountryFilters,
  PagingParams
} from '../../api';
import {inTryCatch} from '../../lib';
import {useAppContext} from '../../context';

import { DistrictsNav } from './DistrictsNav'

export function CountriesList(): JSX.Element {

  const [searchParams, setSearchParams] = useSearchParams()
  const {session, logout} = useAppContext()
  const {id} = useParams()
  const navigate = useNavigate();

  const [countries, setCountries] = useState<DbResponseResultSet | undefined>()
  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [error, showError] = useState<string>()

  const [country, setCountry] = useState<FormikValues>()
  const [loadingCountry, setLoadingCountry] = useState(false)
  const [forceReload, setForceReload] = useState(0)
  const [countryError, setCountryError] = useState<string>()


  const {paging, filter} = useMemo(() => {
    const paging: PagingParams = {
      limit: 100,
      offset: 0,
      sort: 'name',
      order: 'asc',
    }
    if (searchParams.get('limit')) paging.limit = parseInt(searchParams.get('limit') ?? '', 10)
    if (searchParams.get('offset')) paging.offset = parseInt(searchParams.get('offset') ?? '', 10)
    if (searchParams.get('sort')) paging.sort = searchParams.get('sort') ?? 'name'
    if (searchParams.get('order')) paging.order = searchParams.get('order') === 'asc' ? 'asc' : 'desc'

    const filter: QueryCountryFilters = {
      name: '',
      admin_id: '',
      alt_names: '',
      active_program: '',
      app_code_id: '',
      abbreviation: ''
    }

    Object.keys(filter).forEach(prop => {
      if (searchParams.get(prop)) {
        /* @ts-ignore */
        filter[prop] = searchParams.get(prop) ?? ''
      }
    })
    return {paging, filter}
  }, [searchParams])

  function setOffset(offset: number) {
    setSearchParams(prev => {
      prev.set("offset", offset.toString())
      return prev
    }, {replace: true})
  }

  function changeFilter(prop: keyof QueryCountryFilters, val: string) {
    setSearchParams(prev => {
      prev.set(prop, val)
      prev.set('offset', '0')
      return prev
    }, {replace: true})
  }

  function changeSort(prop: string) {
    const newDirection = paging.sort === prop
      ? (
        paging.order === 'asc' ? 'desc' : 'asc'
      ) : 'asc'

    setSearchParams(prev => {
      prev.set('order', newDirection)
      prev.set('sort', prop)
      prev.set('offset', '0')
      return prev
    }, {replace: true})
  }

  async function deleteCountry(id: number) {
    try {
      setCountryError(undefined)
      const result = await new DistrictDao(session).deleteCountry(id)
      navigate(`/districts/countries${window.location.search}`)
    /* @ts-ignore */
    } catch (error: Error) {
      setCountryError((error as Error).message);
    }
  }

  async function saveCountry(id: number, values: FormikValues) {
    setCountryError(undefined)
    try {
      const resultId = await new DistrictDao(session).saveCountry(id, values)
      navigate(`/districts/countries/${resultId}${window.location.search}`)
    /* @ts-ignore */
    } catch (error: Error) {
      setCountryError((error as Error).message);
    }
  }

  useEffect(inTryCatch(setIsLoading, showError, async(state) => {
    const result = await new DistrictDao(session).queryCountries(filter, paging)
    if (!state.mounted) return
    setCountries(result.rows)
    setCount(result.count)
  }), [paging, filter, session])

  useEffect(inTryCatch(setIsLoading, showError, async(state) => {
    if (id) {
      const result = await new DistrictDao(session).getCountry(parseInt(id))
      if (!state.mounted) return
      setCountry(result || {})
    } else {
      setCountry(undefined)
    }
  }), [id, forceReload])


  return (
    <div className="CountriesList">
      <div className="row mb-2">
        <div className="col">
          <DistrictsNav />
        </div>
        <div className="col-md-auto text-md-end">
          <Pager count={count} pager={paging} setOffset={setOffset} />
        </div>
      </div>
      <div className="row">
        <div className="col-9">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <GenericSearchTableHeader 
                  title="Name" 
                  prop='name'
                  filter={filter}
                  setFilter={changeFilter} 
                  paging={paging}
                  changeSort={changeSort}
                  sortInfo={CountrySortInfo} 
                />
                <GenericSearchTableHeader 
                  title="Admin ID" 
                  prop='admin_id'
                  filter={filter}
                  setFilter={changeFilter} 
                  paging={paging}
                  changeSort={changeSort}
                  sortInfo={CountrySortInfo} 
                />
                <GenericSearchTableHeader 
                  title="Alt Names" 
                  prop='alt_names'
                  filter={filter}
                  setFilter={changeFilter} 
                  paging={paging}
                  changeSort={changeSort}
                  sortInfo={CountrySortInfo} 
                />
                <GenericSearchTableHeader 
                  title="App Code ID" 
                  prop='app_code_id'
                  filter={filter}
                  setFilter={changeFilter} 
                  paging={paging}
                  changeSort={changeSort}
                  sortInfo={CountrySortInfo} 
                />
                <GenericSearchTableHeader 
                  title="Abbreviation" 
                  prop='abbreviation'
                  filter={filter}
                  setFilter={changeFilter} 
                  paging={paging}
                  changeSort={changeSort}
                  sortInfo={CountrySortInfo} 
                />
                <GenericSearchTableHeader 
                  title="Active Program?" 
                  prop='active_program'
                  filter={filter}
                  setFilter={changeFilter} 
                  paging={paging}
                  changeSort={changeSort}
                  sortInfo={CountrySortInfo} 
                />
                <th scope="col">
                  <Link to={`/districts/countries/new${window.location.search}`}
                    className="btn btn-primary btn-tiny"
                  >
                    New
                  </Link>
                </th>
              </tr>
            </thead>
            <tbody>
              {countries?.map(row => (
                <tr key={row.id?.toString()}>
                  <td>
                    <Link to={`/districts/regions?country_name=${row.name}`}>
                      {row.name}
                    </Link>
                  </td>
                  <td>{row.admin_id}</td>
                  <td>{row.alt_names && Array.isArray(row.alt_names) ? row.alt_names.join(', ') : '' }</td>
                  <td>{row.app_code_id}</td>
                  <td>{row.abbreviation}</td>
                  <td>{row.active_program ? 'Y' : ''}</td>
                  <td>
                    <Link to={`/districts/countries/${row.id}${window.location.search}`}
                      className="btn btn-outline-primary btn-tiny"
                    >
                      Edit
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="col-3">
          <div className="card mb-2">
            <div className="card-header">
              Country Details
            </div>
            { !id &&
              <div className="card-body">
                No country selected
              </div>
            }
            { id &&
              <LoadablePane className="card-body" key={id}
                loading={loadingCountry || !country}
              >
                <Formik
                  enableReinitialize={true}
                  initialValues={country!}
                  onSubmit={async (values, {setSubmitting}) => {
                    setSubmitting(true);
                    await saveCountry(parseInt(id), values)
                    setSubmitting(false);
                  }}
                >
                {({isSubmitting, values, dirty}) => (
                  <SafeForm dirty={dirty} className="row">
                    <LabeledInput className="col-12" name="name" label="Country Name" />
                    <LabeledTextArea className="col-12" name="alt_names" label="Alt Names" />
                    <LabeledInput className="col-12" name="admin_id" label="Admin ID" type="number" />
                    <LabeledInput className="col-12" name="app_code_id" label="App Code ID" type="number" />
                    <LabeledInput className="col-12" name="abbreviation" label="Abbreviation" />
                    <LabeledSwitch className="col-12" name="active_program" label="Active Program?" />
                    <FormButtons className="col-12 mt-1"
                      buttons={[
                        {
                          label: id === 'new' ? 'Create' : 'Save',
                          disabled: isSubmitting,
                          submitting: isSubmitting
                        }, {
                          label: 'Cancel',
                          type: 'button',
                          className: 'btn btn-default',
                          onClick: () => navigate(`/districts/countries${window.location.search}`),
                          disabled: isSubmitting
                        }, {
                          label: 'Delete',
                          type: 'button',
                          className: 'btn btn-outline-danger float-end',
                          onClick: () => {
                            if (window.confirm("Are you sure you wish to delete this country?  All associated data must be removed.")) {
                              deleteCountry(parseInt(id))
                            }
                          },
                          disabled: isSubmitting || (!id)
                        }
                      ]}
                    />
                  </SafeForm>
                )}
                </Formik>
              </LoadablePane>
            }
            { countryError &&
              <div className="card-body">
                <div className="alert alert-danger" role="alert">
                  {countryError}
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}