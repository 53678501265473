import {useEffect, useMemo, useState} from 'react'
import {useParams, useNavigate, Link} from 'react-router-dom';
import {Formik, Form, FormikValues} from 'formik';
import { useAppContext } from '../../context';
import { ApplicationDao, ApplicationStatuses} from '../../api'
import { LoadablePane, Tabs } from "../../components"
import {FormButtons, FormError, LabeledInput, LabeledSelect, SafeForm} from '../../components/form'
import {inTryCatch} from '../../lib';
import { Request } from './Request';
import { RequestDistricts } from './RequestDistricts';
import {ApplicationApprovals} from './ApplicationApprovals';
import {Notes} from './Notes';
import {Shipment} from './Shipment';
import {Shipments} from './Shipments';
import {TabItem} from '../../components/Tabs';


const StatusOptions = ApplicationStatuses;


export function ApplicationView() {
  
  const params = useParams()
  const application_id = params.id ? +params.id : undefined
  const shipment_id = params.shipment_id ? params.shipment_id : undefined
  const tab = params.tab ? params.tab.toString() : 'request'

  const { session } = useAppContext()
  const navigate = useNavigate();

  // Set to state on load in parallel:
  // Application, Countries for select, ApplicationNotes
  const [application, setApplication] = useState<FormikValues>()
  const [countries, setCountries] = useState<{id: number, name: string}[]>([])
  const [requestId, setRequestId] = useState<number>()

  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState<boolean>(true)
  const [forceReload, setForceReload] = useState(0)


  useEffect(inTryCatch(setLoading, setError, async (state) => {
    const dao = new ApplicationDao(session)
    const result = await dao.get(application_id ?? 0)
    const countries = [{id: null, name: ''}, ...await dao.getCountries()]
    if (!state.mounted) return
    if (result.application)
      setApplication(result.application)
    else
      setApplication({})
    setRequestId(result.requestId)
    setCountries(countries as {id: number, name: string}[])
  }), [session, application_id, forceReload])


  const deleteApplication = async () => {
    if (application_id) {
      try {
        const dao = new ApplicationDao(session);
        await dao.delete(application_id);
        navigate(`/`)
      } catch {
        setError("Error deleting application")
      }
    }
  }


  const availableRequestYears = ():number[] => {
    let years = []
    let start = new Date("1990-01-01").getFullYear();
    let end = new Date().getFullYear() + 2;
    for(let i = start; i <= end; i++) years.push(i);
    return years
  }

  const tabs = () => {
    return application_id ? [
      {
        title: 'Request',
        active: tab === 'request' || tab === undefined,
        href: `/applications/${application_id}`,
        node: <Request key={`r_${application_id}`} 
          id={requestId} 
          forceAppReload={() => {setForceReload(p => p + 1)}} 
          applicationId={+application_id} 
        />
      },
      ...(requestId ? 
        [{
          title: 'Districts', 
          active: tab === 'districts',
          href: `/applications/${application_id}/districts`,
          node: <RequestDistricts key={`rd_${requestId}`} requestId={+requestId} /> }
        ] : 
        []
      ),
      {
        title: 'Approval', 
        active: tab === 'approval',
        href: `/applications/${application_id}/approval`,
        node: <ApplicationApprovals key={`apr_${application_id}`} />
      },
      {
        title: 'Shipments',
        active: tab === 'shipments',
        href: `/applications/${application_id}/shipments`,
        node: <Shipments 
          applicationId={application_id} 
          shipmentId={shipment_id}
          key={`shipments_${application_id}`} 
        />
      }
    ] : 
    [
      {title: 'hidden', node: <div key={0}>waiting to save</div>, href: "#"}
    ]
  }

  return (
    <div className="ApplicationView">
      <div className="d-flex justify-content-between">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/">Applications</Link></li>
            <li className="breadcrumb-item active" aria-current="page">
              {/*{application.app_id}*/}
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">

        {/* Application Details */}
        <div className="col-lg-4">
          <div className="card mb-2">
            <div className="card-header">
              Application Details
            </div>
            <LoadablePane className="card-body" loading={loading || !application}>
              <Formik
                initialValues={application!}
                onSubmit={async (values, {setSubmitting}) => {
                  try {
                    setError(undefined)
                    const isInsert = !application_id
                    const id = await new ApplicationDao(session).save(application_id, values)
                    setForceReload(p => p + 1)
                    setSubmitting(false)
                    if (isInsert)
                      navigate(`/applications/${id}`);
                  } catch (e: any) {
                    setError(e.message)
                  }
                }}
              >
                {({isSubmitting, values, dirty}) => (
                  <SafeForm dirty={dirty} className="row">
                    <LabeledInput className="col-12" name="app_id" />
                    <LabeledSelect className="col-12" name="country_id">
                      {countries.map( ({id, name}) => (
                        <option value={id} key={id}>{name}</option>
                      ))}
                    </LabeledSelect>
                    <LabeledSelect className="col-12" name="status" label="Application Status">
                      {StatusOptions.map( ({label, value}) => (
                        <option value={value} key={value}>{label}</option>
                      ))}
                    </LabeledSelect>
                    <LabeledSelect className="col-12" name="request_year">
                      {availableRequestYears().map(year => (
                        <option value={year} key={year}>{year}</option>
                      ))}
                    </LabeledSelect>
                    <LabeledInput className="col-6" name="submitted_date" type="date"/>
                    <LabeledInput className="col-6" name="approved_date" type="date"/>
                    <LabeledInput className="col-6" name="shipment_arrive_by_date" type="monthyear" label="Arrive By"/>
                    <FormButtons className="col-12 mt-1"
                      buttons={[
                        {
                          label: 'Save',
                          disabled: isSubmitting,
                          submitting: isSubmitting
                        }, {
                          label: 'Cancel',
                          type: 'button',
                          className: 'btn btn-default',
                          onClick: () => setForceReload(p => p + 1),
                          disabled: isSubmitting
                        }, {
                          label: 'Delete',
                          type: 'button',
                          className: 'btn btn-outline-danger float-end',
                          onClick: () => {
                            if (window.confirm("Are you sure you wish to delete this application?  All associated data will be removed from the database.")) {
                              deleteApplication()
                            }
                          },
                          disabled: isSubmitting || (!application_id)
                        }
                      ]}
                    />
                  </SafeForm>
                )}
              </Formik>
            </LoadablePane>
            <FormError error={error} />
          </div>
          <Notes application_id={application_id} />
        </div>

        {/* Data tabs */}
        <div className="col-lg-8">
          <div className="card">
            <Tabs items={tabs()} />
          </div>
        </div>
      </div>
    </div>
  )
}
