import {
  fieldNamedValue,
  fromMonthYear,
  parseAgeGroup,
  parseInt,
  parseEndemicityLf,
  parseEndemicityOncho,
  parseNotTargetedStatus,
  parseSurveysLfTas,
  parseSurveysLfSsSc,
  parseSurveysOncho,
  parseYesNo, empty, parseDate
} from './lib'

import {JRSMParserConfig, JRSMParseResultField, ResultValue} from './types'

export default {
  request_district_data: {
    startRow: 9,
    filter: (fields: JRSMParseResultField[]):boolean => {
      return !empty(fieldNamedValue('region', fields)) || !empty(fieldNamedValue('district', fields))
    },
    fields: [
      {
        name: 'region',
        sheet: /COUNTRY_INFO/,
        column: 'B',
        db: false
      },
      {
        name: 'district',
        sheet: /COUNTRY_INFO/,
        column: 'C',
        db: false
      },
      {
        name: 'population_presac',
        sheet: /COUNTRY_INFO/,
        column: 'E',
        db: true,
        parser: parseInt
      },
      {
        name: 'population_sac',
        sheet: /COUNTRY_INFO/,
        column: 'F',
        db: true,
        parser: parseInt
      },
      {
        name: 'population_adult',
        sheet: /COUNTRY_INFO/,
        column: 'G',
        db: true,
        parser: parseInt
      },
      {
        name: 'endemicity_lf',
        sheet: /COUNTRY_INFO/,
        column: 'H',
        db: true,
        parser: parseEndemicityLf
      },
      {
        name: 'endemicity_oncho',
        sheet: /COUNTRY_INFO/,
        column: 'I',
        db: true,
        parser: parseEndemicityOncho
      },
      {
        name: 'rounds_lf',
        sheet: /COUNTRY_INFO/,
        column: 'P',
        db: true,
        parser: parseInt
      },
      {
        name: 'rounds_oncho',
        sheet: /COUNTRY_INFO/,
        column: 'Q',
        db: true,
        parser: parseInt
      },
      {
        name: 'surveys_lf_tas',
        sheet: /COUNTRY_INFO/,
        column: 'U',
        db: true,
        parser: parseSurveysLfTas
      },
      {
        name: 'surveys_lf_ss_sc',
        sheet: /COUNTRY_INFO/,
        column: 'U',
        db: true,
        parser: parseSurveysLfSsSc
      },
      {
        name: 'surveys_oncho',
        sheet: /COUNTRY_INFO/,
        column: 'V',
        db: true,
        parser: parseSurveysOncho
      },
      {
        name: 'target_pop_village_oncho',
        sheet: /IVM/,
        column: 'I',
        db: true,
        parser: parseInt
      },
      {
        name: 'ivm_stock_remaining',
        sheet: /IVM/,
        column: 'O',
        db: true,
        parser: parseInt
      },
    ]
  },
  requests_shipment_data: {
    fields: [
      {
        name: 'request_year',
        sheet: /SHIP/,
        cell: 'H4',
        db: true
      },
      {
        name: 'import_permit_required',
        sheet: /SHIP/,
        cell: 'H38',
        db: true,
        parser: parseYesNo
      },
      {
        name: 'import_permit_time',
        sheet: /SHIP/,
        cell: 'H39',
        db: true
      },
      {
        name: 'import_permit_docs_required',
        sheet: /SHIP/,
        cell: 'H40',
        db: true,
        parser: parseYesNo
      },
      {
        name: 'import_permit_docs',
        sheet: /SHIP/,
        cell: 'B41',
        db: true
      },
      {
        name: 'consignee_customs_clearance_required',
        sheet: /SHIP/,
        cell: 'H42',
        db: true,
        parser: parseYesNo
      },
      {
        name: 'additional_delivery_info',
        sheet: /SHIP/,
        cell: 'B44',
        db: true
      },
      {
        name: 'additional_info',
        sheet: /SHIP/,
        cell: 'B48',
        db: true
      }
    ]
  },
  requests_ivm_data: {
    startRow: 12,
    endRow: 17,
    filter: (fields: JRSMParseResultField[]):boolean => {
      return /ive/i.test(fieldNamedValue('pc_medicine', fields)+'')
    },
    fields: [
      {
        name: 'pc_medicine',
        sheet: /SUMMARY/,
        column: 'B',
        db: false
      },
      {
        name: 'ivm_required',
        sheet: /SUMMARY/,
        column: 'C',
        parser: parseInt,
        db: true
      },
      {
        name: 'ivm_in_stock',
        sheet: /SUMMARY/,
        column: 'D',
        parser: parseInt,
        db: true
      },
      {
        name: 'ivm_in_pipeline',
        sheet: /SUMMARY/,
        column: 'E',
        parser: parseInt,
        db: true
      },
      {
        name: 'ivm_requested',
        sheet: /SUMMARY/,
        column: 'F',
        parser: parseInt,
        db: true
      },
    ]
  },
  requests_to_treat_lf: {
    startRow: 22,
    endRow: 25,
    filter: (fields: JRSMParseResultField[]):boolean => {
      return /fil/i.test(fieldNamedValue('disease', fields)+'')
    },
    fields: [
      {
        name: 'disease',
        sheet: /SUMMARY/,
        column: 'B',
        db: false
      },
      {
        name: 'people_to_treat_r1_lf',
        sheet: /SUMMARY/,
        column: 'G',
        parser: parseInt,
        db: true
      },
      {
        name: 'people_to_treat_r2_lf',
        sheet: /SUMMARY/,
        column: 'H',
        parser: parseInt,
        db: true
      }
    ]
  },
  requests_to_treat_oncho: {
    startRow: 22,
    endRow: 25,
    filter: (fields: JRSMParseResultField[]):boolean => {
      return /onc/i.test(fieldNamedValue('disease', fields)+'')
    },
    fields: [
      {
        name: 'disease',
        sheet: /SUMMARY/,
        column: 'B',
        db: false
      },
      {
        name: 'people_to_treat_r1_oncho',
        sheet: /SUMMARY/,
        column: 'G',
        parser: parseInt,
        db: true
      },
      {
        name: 'people_to_treat_r2_oncho',
        sheet: /SUMMARY/,
        column: 'H',
        parser: parseInt,
        db: true
      }
    ]
  },
  requests_dates: {
    startRow: 32,
    endRow: 37,
    filter: (fields: JRSMParseResultField[]):boolean => {
      return /ive/i.test(fieldNamedValue('pc_medicine', fields)+'')
    },
    fields: [
      {
        name: 'pc_medicine',
        sheet: /SUMMARY/,
        column: 'B',
        db: false
      },
      {
        name: 'r1_month',
        sheet: /SUMMARY/,
        column: 'C',
        db: false
      },
      {
        name: 'r1_year',
        sheet: /SUMMARY/,
        column: 'D',
        db: false
      },
      {
        name: 'r2_month',
        sheet: /SUMMARY/,
        column: 'E',
        db: false
      },
      {
        name: 'r2_year',
        sheet: /SUMMARY/,
        column: 'F',
        db: false
      },
      {
        name: 'arrive_month',
        sheet: /SUMMARY/,
        column: 'G',
        db: false
      },
      {
        name: 'arrive_year',
        sheet: /SUMMARY/,
        column: 'H',
        db: false
      },
      {
        name: 'mda1_date',
        db: true,
        sheet: /SUMMARY/,
        mapper: (fields: JRSMParseResultField[]): ResultValue => {
          const month = fieldNamedValue('r1_month', fields)
          const year = fieldNamedValue('r1_year', fields)
          return fromMonthYear(month, year)
        }
      },
      {
        name: 'mda2_date',
        db: true,
        sheet: /SUMMARY/,
        mapper: (fields: JRSMParseResultField[]): ResultValue => {
          const month = fieldNamedValue('r2_month', fields)
          const year = fieldNamedValue('r2_year', fields)
          return fromMonthYear(month, year)
        }
      },
      {
        name: 'shipment_arrive_by_date',
        db: true,
        sheet: /SUMMARY/,
        mapper: (fields: JRSMParseResultField[]): ResultValue => {
          const month = fieldNamedValue('arrive_month', fields)
          const year = fieldNamedValue('arrive_year', fields)
          return fromMonthYear(month, year)
        }
      }
    ]
  },

  // Request Contacts
  request_contacts_1: {
    fields: [
      { name: 'name', sheet: /SHIP/, cell: 'C9', db: true },
      { name: 'department', sheet: /SHIP/, cell: 'C10', db: true },
      { name: 'organization', sheet: /SHIP/, cell: 'C11', db: true },
      { name: 'phone', sheet: /SHIP/, cell: 'C12', db: true },
      { name: 'fax', sheet: /SHIP/, cell: 'C13', db: true },
      { name: 'email', sheet: /SHIP/, cell: 'C14', db: true },
      { name: 'mailing_address', sheet: /SHIP/, cell: 'C15', db: true },
      { name: 'type', sheet: /SHIP/, value: 'consignee', db: true }
    ],
    filter: (fields) => {
      return !empty(fieldNamedValue('name', fields))
    },
  },
  request_contacts_2: {
    fields: [
      { name: 'name', sheet: /SHIP/, cell: 'C18', db: true },
      { name: 'department', sheet: /SHIP/, cell: 'C19', db: true },
      { name: 'organization', sheet: /SHIP/, cell: 'C20', db: true },
      { name: 'phone', sheet: /SHIP/, cell: 'C21', db: true },
      { name: 'fax', sheet: /SHIP/, cell: 'C22', db: true },
      { name: 'email', sheet: /SHIP/, cell: 'C23', db: true },
      { name: 'mailing_address', sheet: /SHIP/, cell: 'C24', db: true },
      { name: 'type', sheet: /SHIP/, value: 'consignee', db: true }
    ],
    filter: (fields: JRSMParseResultField[]):boolean => {
      return !empty(fieldNamedValue('name', fields))
    },
  },
  request_contacts_3: {
    fields: [
      { name: 'name', sheet: /SHIP/, cell: 'C27', db: true },
      { name: 'department', sheet: /SHIP/, cell: 'C28', db: true },
      { name: 'organization', sheet: /SHIP/, cell: 'C29', db: true },
      { name: 'phone', sheet: /SHIP/, cell: 'C30', db: true },
      { name: 'fax', sheet: /SHIP/, cell: 'C31', db: true },
      { name: 'email', sheet: /SHIP/, cell: 'C32', db: true },
      { name: 'mailing_address', sheet: /SHIP/, cell: 'C33', db: true },
      { name: 'type', sheet: /SHIP/, value: 'consignee', db: true }
    ],
    filter: (fields: JRSMParseResultField[]):boolean => {
      return !empty(fieldNamedValue('name', fields))
    },
  },
  request_contacts_4: {
    fields: [
      { name: 'name', sheet: /SHIP/, cell: 'F9', db: true },
      { name: 'department', sheet: /SHIP/, cell: 'F10', db: true },
      { name: 'organization', sheet: /SHIP/, cell: 'F11', db: true },
      { name: 'phone', sheet: /SHIP/, cell: 'F12', db: true },
      { name: 'fax', sheet: /SHIP/, cell: 'F13', db: true },
      { name: 'email', sheet: /SHIP/, cell: 'F14', db: true },
      { name: 'mailing_address', sheet: /SHIP/, cell: 'F15', db: true },
      { name: 'type', sheet: /SHIP/, value: 'deliverypoint', db: true }
    ],
    filter: (fields: JRSMParseResultField[]):boolean => {
      return !empty(fieldNamedValue('name', fields))
    },
  },
  request_contacts_5: {
    fields: [
      { name: 'name', sheet: /SHIP/, cell: 'F18', db: true },
      { name: 'department', sheet: /SHIP/, cell: 'F19', db: true },
      { name: 'organization', sheet: /SHIP/, cell: 'F20', db: true },
      { name: 'phone', sheet: /SHIP/, cell: 'F21', db: true },
      { name: 'fax', sheet: /SHIP/, cell: 'F22', db: true },
      { name: 'email', sheet: /SHIP/, cell: 'F23', db: true },
      { name: 'mailing_address', sheet: /SHIP/, cell: 'F24', db: true },
      { name: 'type', sheet: /SHIP/, value: 'deliverypoint', db: true }
    ],
    filter: (fields: JRSMParseResultField[]):boolean => {
      return !empty(fieldNamedValue('name', fields))
    },
  },
  request_contacts_6: {
    fields: [
      { name: 'name', sheet: /SHIP/, cell: 'F27', db: true },
      { name: 'department', sheet: /SHIP/, cell: 'F28', db: true },
      { name: 'organization', sheet: /SHIP/, cell: 'F29', db: true },
      { name: 'phone', sheet: /SHIP/, cell: 'F30', db: true },
      { name: 'fax', sheet: /SHIP/, cell: 'F31', db: true },
      { name: 'email', sheet: /SHIP/, cell: 'F32', db: true },
      { name: 'mailing_address', sheet: /SHIP/, cell: 'F33', db: true },
      { name: 'type', sheet: /SHIP/, value: 'deliverypoint', db: true }
    ],
    filter: (fields: JRSMParseResultField[]):boolean => {
      return !empty(fieldNamedValue('name', fields))
    },
  },
  request_contributors: {
    startRow: 75,
    endRow: 78,
    fields: [
      {
        name: 'title',
        sheet: /SUMMARY/,
        column: 'B',
        db: true
      },
      {
        name: 'name',
        sheet: /SUMMARY/,
        column: 'C',
        db: true
      },
      {
        name: 'phone',
        sheet: /SUMMARY/,
        column: 'E',
        db: true
      },
      {
        name: 'email',
        sheet: /SUMMARY/,
        column: 'G',
        db: true
      },
      {
        name: 'contribution_date',
        sheet: /SUMMARY/,
        column: 'H',
        parser: parseDate,
        db: true
      }
    ]
  },
  request_funding: {
    startRow: 64,
    endRow: 67,
    filter: (fields: JRSMParseResultField[]):boolean => {
      return /fil|onc/i.test(fieldNamedValue('disease', fields)+'')
    },
    fields: [
      {
        name: 'disease',
        sheet: /SUMMARY/,
        column: 'B',
        db: true
      },
      {
        name: 'num_treatments',
        sheet: /SUMMARY/,
        column: 'C',
        parser: parseInt,
        db: true
      },
      {
        name: 'funding_secured',
        sheet: /SUMMARY/,
        column: 'D',
        db: true
      },
      {
        name: 'funding_source',
        sheet: /SUMMARY/,
        column: 'E',
        db: true
      },
      {
        name: 'amount',
        sheet: /SUMMARY/,
        column: 'H',
        db: true,
        parser: parseInt
      }
    ]
  },
  request_not_targeted: {
    startRow: 42,
    endRow: 51,
    filter: (fields: JRSMParseResultField[]):boolean => {
      return /ive/i.test(fieldNamedValue('medicine', fields)+'')
    },
    fields: [
      {
        name: 'medicine',
        sheet: /SUMMARY/,
        column: 'B',
        db: false
      },
      {
        name: 'pc_medicine',
        sheet: /SUMMARY/,
        value: 'ivermectin',
        db: true
      },
      {
        name: 'age_group',
        sheet: /SUMMARY/,
        column: 'C',
        parser: parseAgeGroup,
        db: true
      },
      {
        name: 'num_people',
        sheet: /SUMMARY/,
        column: 'D',
        parser: parseInt,
        db: true
      },
      {
        name: 'num_tablets',
        sheet: /SUMMARY/,
        column: 'E',
        parser: parseInt,
        db: true
      },
      {
        name: 'source',
        sheet: /SUMMARY/,
        column: 'F',
        parser: parseInt,
        db: true
      },
      {
        name: 'status',
        sheet: /SUMMARY/,
        column: 'H',
        parser: parseNotTargetedStatus,
        db: true
      }
    ]
  }
} as JRSMParserConfig
