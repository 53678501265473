import axios from 'axios'
import {FileData} from '../components/form';
import {FormikValues} from 'formik';
import {SessionData} from './Session';
import {config} from '../Config';

const URLs = {
  execute: '/api/execute'
}


export type DbLiteralTypes = string | number | boolean | null | string[]
export interface DbConnection {
  user: string
  host: string
  database: string
  password: string
  port?: number
}
export interface DbRequest extends DbRequestParam{
  db_connection: DbConnection
}
export interface DbRequestParam {
  statements: DbRequestStatement[]
}
export type DbRequestStatement = [string, ...DbLiteralTypes[]]
export type DbResponse = Array<DbResponseResultSet>
export type DbResponseResultSet = Array<DbResponseResultRow>
export type DbResponseResultRow = Record<string, DbLiteralTypes>




export interface SavedFileData {
  name: string
  type: string
  file_id: number
  upload_date: string
  application_file_id?: string
  remove?: boolean
}

export interface DbNewFileData {
  name: string
  type: string
  postgresHex: string
}

export type DbFileData = SavedFileData | DbNewFileData



export interface PagingParams {
  limit: number,
  offset: number,
  sort: string,
  order: 'asc' | 'desc',
}

export type SortingInfo = Readonly<Record<string, Readonly<{columns?: string[], dataType: 'alpha' | 'num'}>>>

export class WhereBuilder {
  private whereClause = ['1=1']

  public and(filter: number | string | boolean | undefined, clause: string): void {
    if (filter)
    {
      this.whereClause.push(' AND (' + clause + ')')
    }
  }
  public build(): string {
    return (this.whereClause.length > 1) ? 'WHERE ' + this.whereClause.join('') : ''
  }
}




function convertNamedParamsToNumberedParams(statement: string, params: Record<string, DbLiteralTypes>): DbRequestStatement {
  const aryParams: DbLiteralTypes[] = []

  const propNames = Object.keys(params)
    .sort((a, b) => b.length - a.length)
  // sorted, b/c you want to find/replace longer names first
  // in order to handle the :shoe & :shoelace scenario properly

  for(const prop of propNames) {
    const namedParam = `:${prop}`
    if (statement.includes(namedParam)) {
      const num = aryParams.push(params[prop])
      statement = statement.replaceAll(namedParam, `$${num}`)
    }
  }

  return [statement, ...aryParams];
}

export class Api {

  private readonly connection: Readonly<DbConnection>

  constructor(sessionData: SessionData) {
    this.connection = {
      ...config,
      ...sessionData,
    }
  }

  protected async execute(statement: string, params: Record<string, DbLiteralTypes> = {}): Promise<DbResponseResultSet> {
    const req: DbRequest = {
      statements: [convertNamedParamsToNumberedParams(statement, params)],
      db_connection: this.connection
    }

    const { data } = await axios.post<any>(URLs.execute, req)

    if ('error' in data)
      throw new Error(data.error)

    return (data.results as DbResponse)[0]
  }



  protected nullsToEmptyStrings(record: DbResponseResultRow | undefined): FormikValues | undefined {
    if (!record)
      return undefined

    const result: FormikValues = {}
    for (const prop of Object.keys(record)) {
      const val = record[prop]
      if (val === null)
        result[prop] = ''
      else
        result[prop] = val
    }

    return result
  }

  protected emptyStringsToNulls(values: FormikValues): Record<string, any> {
    const result: Record<string, any> = {}
    for (const prop of Object.keys(values)) {
      const val = values[prop]
      if (val === '')
        result[prop] = null
      else {
        if (Array.isArray(val)) {
          result[prop] = val.map(v => this.emptyStringsToNulls(v))
        } else if (typeof val === 'object')
          result[prop] = this.emptyStringsToNulls(val)
        else
          result[prop] = val
      }
    }

    return result
  }



  private buf2hex(ary: Uint8Array) {
    return [...ary]
      .map(x => x.toString(16).padStart(2, '0'))
      .join('');
  }

  private async readAsPostgresHex(file: File): Promise<string> {
    const reader = file.stream().getReader()
    const builder: string[] = ['\\x'] // '\x' tells Postgres this is a hex string
    while (true) {
      const {done, value} = await reader.read()
      if (done) break

      builder.push(this.buf2hex(value))
    }
    return builder.join('')
  }

  protected async toDbFile(file: FileData | undefined): Promise<DbFileData | undefined> {
    if (typeof file === 'undefined')
      return undefined

    if ('file' in file)
      return {
        name: file.name,
        type: file.type,
        postgresHex: await this.readAsPostgresHex(file.file)
      }

    return file
  }


}
