
export function IntegerView({
  value
}: {
  value: any
}): JSX.Element {
  return (
    <>{value ? value.toLocaleString('en-us') : ''}</>
  );
}
