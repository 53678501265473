import {FileData, FileInput, FormButtons, FormError, NewFileData} from '../../components/form';
import {useState} from 'react';
import {parseJRSM} from '../../parser';
import {read} from 'xlsx';
import {readFile, convertJrsmResultToRequest} from '../../lib';
import {DbResponseResultRow, RequestDao} from '../../api';
import {useAppContext} from '../../context';
import {FormikValues} from 'formik';
import {DownloadButton} from '../..//components';




export function RequestJrsmFilePanel(
  {
    jrsmFile,
    requestId,
    applicationId,
    onSaved,
  } : {
    jrsmFile: FileData | undefined
    requestId: number | undefined
    applicationId: number
    onSaved: (requestId: number) => void
  }
): JSX.Element {
  const { session } = useAppContext()

  const [requestToImport, setRequestToImport] = useState<FormikValues>()
  const [fileToConfirm, setFileToConfirm] = useState<NewFileData>()
  const [busyState, setBusyState] = useState<string>()
  const [error, setError] = useState<string>()

  function onFileChange(file: NewFileData) {
    (async () => {
      try {
        setBusyState('reading file')

        setError(undefined)
        const workbook = await readFile(file.file, buf => read(buf))
        const jrsmParseResult = parseJRSM(workbook)
        console.log(jrsmParseResult);
        setBusyState('matching districts')
        if (jrsmParseResult.error){
          setError(jrsmParseResult.error)
          return
        }
        const request = await convertJrsmResultToRequest(jrsmParseResult, session, applicationId, requestId)


        setRequestToImport(request)
        setFileToConfirm(file)

      } finally {
        setBusyState(undefined)
      }
    })().catch(e => {
      console.error(e)
      setError(typeof e === 'string' ? e : e.message)
    })
  }




  function onConfirmFile() {
    (async () => {
      if (!requestToImport)
        throw new Error('dataToConfirm is empty.  This should never happen')
      if (!fileToConfirm)
        throw new Error('fileToConfirm is empty.  This should never happen')

      try {
        setBusyState('saving')

        const id = await new RequestDao(session).save(requestToImport, applicationId, fileToConfirm)

        onSaved(id)

      } finally {
        setBusyState(undefined)
      }
    })().catch(e => setError(typeof e === 'string' ? e : e.message))
  }

  function onCancel() {
    setFileToConfirm(undefined)
    setRequestToImport(undefined)
    setError(undefined)
  }



  return (
    <div className="card text-bg-light">
      <div className="card-body">
        <div className="row mb-3">
          <div className="col-12">JRSM Excel File:</div>

          {!!busyState
            ? (<div>{ busyState }</div>)
            : (
              <div className="col-md-12">
                {!fileToConfirm
                  ? (
                    <>
                      <FileInput value={jrsmFile} onChange={onFileChange} />
                      {jrsmFile &&
                        <div className="form-text">Uploaded on {!!jrsmFile && 'upload_date' in jrsmFile ? jrsmFile.upload_date : ''}</div>
                      }
                    </>
                  )
                  : (<div className="col-lg-10"><FileInput value={fileToConfirm} onChange={onFileChange} /></div>)}
              </div>
            )}
        </div>
        <div className="row">
          <div className="col">Uploading a new JRSM file will update the Request and District data</div>
        </div>
        { !!fileToConfirm && (
          <div className="row">
            <FormButtons className="col-12 mt-2"
                         buttons={[
                           {
                             label: 'Save data',
                             type: 'button',
                             onClick: onConfirmFile,
                             disabled: !!busyState,
                           }, {
                             label: 'Cancel',
                             type: 'button',
                             className: 'btn btn-default',
                             onClick: onCancel,
                             disabled: !!busyState,
                           }
                         ]}
            />
          </div>
        )}
        <FormError error={error} />
      </div>
    </div>
  )
}

