import moment from 'moment'
import 'moment/locale/fr'
import 'moment/locale/es'
import * as chrono from 'chrono-node'
import {JRSMParseResultField, ParserFunction, ResultValue} from '../types'

const DateImportFormt = 'YYYY-MM-DD'
const Locales = ['en', 'fr', 'es']
const MonthFormats = ['LLLL', 'LLL']

// @ts-ignore
const tryToParseMonth = (monthString) => {
  for (let l = 0; l < Locales.length; l++) {
    let locale = Locales[l]
    for (let m = 0; m < MonthFormats.length; m++) {
      let month = moment(monthString, MonthFormats[m], locale)
      if (month.isValid()) {
        moment.locale('en')
        return month.format('MM')
      }
    }
  }
  moment.locale('en')
}
// @ts-ignore
export const fromExcelDate = (dateString, format='MMMM DD, YYYY') => {
  return moment(dateString, format).format(DateImportFormt)
}
// @ts-ignore
export const fromUnknownDate = (dateString) => {
  const parsedDate = chrono.parseDate(dateString)
  if (parsedDate === null)
    throw new Error(`Error parsing date: ${dateString}`)
  return moment(parsedDate).format(DateImportFormt)
}
// @ts-ignore
export const fromMonthYear = (month, year) => {
  const parsedMonth = month && month.length && month.length > 0 ? 
    tryToParseMonth(month) : ''
  if (parsedMonth) {
    return moment(`${parsedMonth}-${year}`, 'MM-YYYY').format(DateImportFormt)
  } else {
    return null
  }
}

export const empty = (data: string | number | boolean | Date | null | undefined): boolean => {
  if (typeof(data) === 'number' || typeof(data) == 'boolean') return false
  if (typeof(data) === 'undefined' || data === null) return true
  if (typeof(data) === 'string') {
    return data === null || data.match(/^\s*$/) !== null
  }
  // 'data' can only be a Date at this point
  return false
}

export const parseInt: ParserFunction = (value) => {
  if (empty(value) || typeof (value) === 'undefined') return null
  if (typeof(value) === 'number')
    return Math.round(value)
  if (typeof(value) === 'boolean')
    return  value ? 1 : 0
  if (typeof(value) === 'string')
   return Math.round(Number.parseFloat(value))

  // value is a Date
  return 0 // not sure about this?  Maybe null instead?  Or return the number of days from Dec 30, 1899?
}


export const parseDate: ParserFunction = (value) => {
  if (empty(value) || typeof (value) === 'undefined') return null
  if (typeof value === 'number')
    return moment('1899-12-30').add(value, 'day').format(DateImportFormt); // Excel dates can be stored as numbers from 1899-12-30
  if (typeof(value) === 'boolean')
    throw new Error('not sure how to parse a boolean to a Date')
  if (typeof(value) === 'string')
    return fromUnknownDate(value)
  return moment(value).format(DateImportFormt)
}

const parseEndemicity: ParserFunction = (value) => {
  if (empty(value)) return null

  const val = `${value}`
  if (val === '0') return 'non_endemic';
  if (val === '1') return 'endemic';
  if (val === '4') return 'status_unknown';
  if (val === '19') return 'endemic_pending_impact_survey';
  if (val === '99') return 'endemic_mda_stopped';
  return null
}

export const parseYesNo: ParserFunction = (value) => {
  if (empty(value)) return null
  if (/yes|oui|si/i.test(value+'')) return true;
  else if (/no|non/i.test(value+'')) return false;
  return null;
}

export const parseEndemicityLf = parseEndemicity
export const parseEndemicityOncho = parseEndemicity
export const parseEndemicityLoaLoa = parseEndemicity

export const parseSurveysLfTas: ParserFunction = (value) => {
  return value === 'TAS' || value === 'Both'
}

export const parseSurveysLfSsSc: ParserFunction = (value) => {
  return value === 'SS/SC' || value === 'Both'
}

export const parseSurveysOncho: ParserFunction = (value) => {
  return value === 'Yes'
}

export const parseAgeGroup: ParserFunction = (value) => {
  if (empty(value)) return null
  if (/pre|eap/i.test(value+'')) return 'presac';
  if (/sac|eas/i.test(value+'')) return 'sac';
  if (/ad/i.test(value+'')) return 'adult';
  if (/wc/i.test(value+'')) return 'wcba';
  return null;
}

export const parseNotTargetedStatus: ParserFunction = (value) => {
  if (empty(value)) return null
  if (/requi/i.test(value+'')) return 'required';
  if (/reque|dema/i.test(value+'')) return 'requested';
  if (/recei|reç/i.test(value+'')) return 'received';
  return null;
}

export const fieldNamedValue = (
  name:string, 
  fields:JRSMParseResultField[]
): ResultValue|undefined => {
  for (let i = 0; i < fields.length; i++) {
    if (fields[i].name === name) return fields[i].value
  }
}
